import * as React from "react"
import {graphql, useStaticQuery} from 'gatsby'

import "../styles/index.css"
import "../styles/indexa.css"
import "../styles/indexb.css"
import "../styles/indexc.css"
import "../styles/indexd.css"
import "../styles/indexe.css"
import "../styles/indexf.css"
import "../styles/indexg.css"
import "../styles/indexh.css"
import "../styles/indexshh.css"
import "../styles/noncest.css"

// markup 
const IndexPage = () => {
	const data = useStaticQuery(graphql`
	query {
		allContentfulProducts(filter: {id: {eq: "a7b7cde3-45ef-5fad-acbe-0f18b41271e7"}})  {
			edges {
			  node {
				title
				price
				description
				image {
				  file {
					url
				  }
				}
			  }
			}
		  }
	}
	`)

  return (
    <main>
		<html lang="en" id="facebook" class="">
			<head>
				<title id="pageTitle">Etouch | Equipos y visores de VR
				</title>
			</head>
			<body class="webkit win x1 Locale_es_LA _19_u" dir="ltr">
				<div class="_li">
					<div class="_919u _li">
						<div class="_7dwk" id="u_0_5y">
							<iframe class="_rp24" frameborder="0" scrolling="no" src="https://www.oculus.com/tealium/?env=prod" title="919u">
							</iframe>
						</div>
						<div class="_4367 _8orj _9bu8">
							<div class="_9iup">
								<section class="_4gsw _7og" id="u_0_5z">
									<div class="_7p2">
									</div>
									<div class="_44mf">
										<section colorscheme="dark" class="_4gsw _7og">
											<div class="_7p2">
											</div>
										</section>
									</div>
								</section>
							</div>
							<div class="_9i79">
								<div class="_9i7a _OuiPlutoNavigationBar__cfOpaque _9e6k _9ec1 _9ech _9ecd _9ece _9eb_ _9i7b" data-testid="navbar-primary" id="u_0_4f">
									<div class="_9ebw">
										<div class="_3bdh _2zis _9e99">
											<div class="_1bto _1bts">
												<div class="_31m4 _9epc _9ey-">
												</div>
											</div>
											<div class="_1bto _1btp _1btq _7ujy">
												<div class="_31m4 _9epc _31n9">
												</div>
											</div>
											<div class="_1bto _1bts _1btp _1btq">
												<div>
													<a class="_2v0- _2v12" href="/" id="u_0_4g">
														<div class="_9ebx" id="u_0_4h">
															<img class="_9eby img" aria-label="Oculus" src={`LuM5_oJjZsU.svg`} alt=""/>
															<img class="_9ebz img" src={`X177feMMdb4.svg`} alt=""/>
															<img class="_9eb- img" src={`dEwO7QAdmX5.svg`} alt=""/>
														</div>
													</a>
													<a class="_2v0- _2v12 _8loh" href="/support/">
													</a>
												</div>
											</div>
											<div class="_1bto _1btp _1btq _7ujz">
												<div id="u_0_4i">
													<div class="_vzy _1p0c" tabindex="0" role="button">
														<i class="menu _3n44 _3n45 _3n46 _3n47" colorscheme="light">
														</i>
													</div>
												</div>
											</div>
										</div>
										<div class="_9e7q _9e79 _9ec1 _9e7k">
											<span class="_9h5p">
												<a class="_9e7d _9e7g" data-testid="navlink-productos" href="#u_0_1m" id="u_0_4k">PRODUCTOS&nbsp;&nbsp;
													<span class="_9e7z">
														<span class="_924- _9250">
															<i class="img sp_wUQJPOLecXu sx_b13214">
															</i>
														</span>
													</span>
												</a>
												<div class="_9e7i _OuiPlutoNavigationBar__cfOpaque _9e6k _9ec1 _9ec6 _9ech _9ecd _9ece" data-testid="navbar-dropdown" id="u_0_4l">
													<div class="_9ebw">
														<div class="_9e7q _9e7y _9ec1 _9e7k _9e7l _9e7o">
															<span class="_9h5p">
																<a class="_9e7d _9e7f _9e7t _9e7u" data-testid="navlink-oculus&nbsp;quest&nbsp;2" href="/support/" id="u_0_4m">
																	<span class="_924-">
																		<i class="img sp_wUQJPOLecXu sx_b13214">
																		</i>
																	</span>
																	<div class="_9e7j">
																		<p class="_9d3p _9d3r _9d3u _9d4g _9e74">OCULUS&nbsp;QUEST&nbsp;2
																		</p>
																		<p class="_9d3p _9d3r _9d3u _9d4f _9e75">VR TODO EN UNO
																		</p>
																	</div>
																</a>
															</span>
															<span class="_9h5p">
																<a class="_9e7d _9e7f _9e7t _9e7u" data-testid="navlink-oculus&nbsp;rift&nbsp;s" href="/support/" id="u_0_4n">
																	<span class="_924-">
																		<i class="img sp_wUQJPOLecXu sx_aecca3">
																		</i>
																	</span>
																	<div class="_9e7j">
																		<p class="_9d3p _9d3r _9d3u _9d4g _9e74">OCULUS&nbsp;RIFT&nbsp;S
																		</p>
																		<p class="_9d3p _9d3r _9d3u _9d4f _9e75">JUEGOS DE VR PARA PC
																		</p>
																	</div>
																</a>
															</span>
															<span class="_9h5p">
																<a class="_9e7d _9e7f _9e7t _9e7u" data-testid="navlink-accesorios" href="/support/" id="u_0_4o">
																	<span class="_924-">
																		<i class="img sp_wUQJPOLecXu sx_c55d4e">
																		</i>
																	</span>
																	<div class="_9e7j">
																		<p class="_9d3p _9d3r _9d3u _9d4g _9e74">ACCESORIOS
																		</p>
																		<p class="_9d3p _9d3r _9d3u _9d4f _9e75">ACTUALIZA TU SISTEMA DE VR
																		</p>
																	</div>
																</a>
															</span>
															<span class="_9h5p">
																<a class="_9e7q _9e7y _9e7d _9e7f _9e7t _9e6_ _9e77" data-testid="navlink-comparar-visores" href="/support/" id="u_0_4p">
																	<p class="_9e70">COMPARAR VISORES
																	</p>
																	<i class="_9e71 img sp_wUQJPOLecXu sx_db63aa">
																	</i>
																</a>
															</span>
														</div>
													</div>
												</div>
											</span>
											<span class="_9h5p">
												<a class="_9e7d _9e7g" data-testid="navlink-ayuda" href="/support/" id="u_0_4r">CONTÁCTANOS
												</a>
											</span>
										</div>
										<div class="_3bdh _2zit _9e9a _9ecf">
										<div class="_1bto _1bts _7ujz">
											<div class="_31m4 _9epc _31o1">
											</div>
										</div>
										<div class="_1bto _1bts _1btq">
											<div data-testid="home-login-button" class="_9epd" id="u_0_4s">
												<a class="_9eow" href="/support/">
													<span class="_924- _9250">
														<i class="img sp_wUQJPOLecXu sx_a1bc64">
														</i>
													</span>
													<span class="_9j-e">
														<span class="_924- _9250">
															<i class="img sp_wUQJPOLecXu sx_4cd1d9">
															</i>
														</span>
													</span>
												</a>
											</div>
										</div>
										<div class="_1bto _1bts _7uj_">
											<div class="_31m4 _9epc _31n9 _7uju _9isr">
											</div>
										</div>
										<div class="_1bto _1btq _7ujy">
											<div class="_31m4 _9epc _31n9">
											</div>
										</div>
										<div class="_1bto _1bts _1btp _1btq">
											<a class="_36bs" href="/support/" id="u_0_4t">
												<span class="_36bt">
													<span class="_924- _9250">
														<i class="_9251 img sp_wUQJPOLecXu sx_c78c37">
														</i>
														<i class="_9252 img sp_wUQJPOLecXu sx_56b1ce">
														</i>
													</span>
												</span>
												<span class="_9jil">
													<span class="_924- _9250">
														<i class="_9251 img sp_wUQJPOLecXu sx_c78c37">
														</i>
														<i class="_9252 img sp_wUQJPOLecXu sx_56b1ce">
														</i>
													</span>
												</span>
											</a>
										</div>
										<div class="_1bto _1bts _7ujz">
											<div class="_31m4 _9epc _31o1">
											</div>
										</div>
										<div class="_1bto _1btp _1btq">
											<div class="_31m4 _9epc _31na">
											</div>
										</div>
									</div>
									</div>
								</div>
							</div>
							<div>
								<div class="_rp1">
								</div>
							</div>
							<div>
								<div class="_8ky- _1xcn" data-testid="oui_cms_page">
									<div>
										<div>
											<style>
											</style>
											<section class="_4gsw _7of home-hero__section">
												<div class="_10sg _1dv2 _rp25">
												</div>
												<div class="_7p2">
													<h1 class="_9d4r _9d4t _9d4w hero__title _rp3" >DESCONÉCTATE Y JUEGA DE VERDAD
													</h1>
													<div class="_7uym">
														<div class="_1g5f _1g5l _1g5h">
															<div class="_2h23 _2h24 home-hero__parallax-content">
																<div class="_2jkl" id="u_0_0">
																	<div class="home-hero__parallax-bg">
																	</div>
																</div>
															</div>
														</div>
													</div>					
													<div class="home-hero__container">
														<div class="_7uym">
															<span id="u_0_1">
																<img class="_1fm8 _rp4" src={`115953981_418734742420514_7636647693767013895_n.jpg`} alt=""/>
															</span>
														</div>
														<div class="_7uym">
															<div class="_1g5f _1g5j _1g5g">
																<div class="_2h23 _2h24">
																	<div>
																		<div class="_45hp _rp5" transitiondelay="600" transitiontime="1200" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="0" scaleend="1" blurstart="0" blurend="0" transformorigin="100% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																			<div class="_3mvi">
																				<div class="_2jkl parallax__container controller-image__container-left" id="u_0_7j">
																					<span id="u_0_7k">
																						<img alt="" class="_1fm8 controller-image__left img" src={`117696691_330556574980899_4744432118000623224_n.png`}/>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="_7uym">
															<div class="_1g5f _1g5j _1g5i">
																<div class="_2h23 _2h24">
																	<div>
																		<div class="_45hp _rp7" transitiondelay="600" transitiontime="1200" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="0" scaleend="1" blurstart="0" blurend="0" transformorigin="0% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																			<div class="_3mvi">
																				<div class="_2jkl parallax__container controller-image__container-right" id="u_0_7h">
																					<span id="u_0_7i">
																						<img alt="" class="_1fm8 controller-image__right img" src={`117643546_784243395723859_3881960650508091403_n.png`}/>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="_7uym">
															<div class="_1g5f _1g5j _1g5h">
																<div class="_2h23 _2h24">
																	<div>
																		<div class="_45hp _rp8" transitiondelay="600" transitiontime="1200" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="0" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																			<div class="_3mvi">
																				<div class="_2jkl parallax__container headset__image-container" id="u_0_8x">
																					<span id="u_0_8y">
																						<img alt="" class="_1fm8 headset__image img" src={`117579195_308145183576485_5565185509721086515_n.png`}/>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="_1g5f _1g5l _1g5h">
															<div class="_2h23 _2h24 home-hero__content">
																<h2 class="_9d4r _9d4t _9d4y hero__sub-title _rp3" >NUEVO OCULUS&nbsp;QUEST&nbsp;2. SIN CABLES. SIN LÍMITES. UN NUEVO NIVEL EN VIDEOJUEGOS.
																</h2>
																<div class="controllers__container">
																	<div class="" id="u_0_5">
																		<div>
																			<div class="_4r36">
																				<div class="_66ge _rp10" filterkeys="" cols="6" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																					<span>
																						<div data-filter-keys="[]" data-cols="6" data-prefix="0" data-pull="0" data-push="0" data-suffix="0">
																							<div>
																								<div class="_45hp _rp11" transitiondelay="0" transitiontime="1000" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="1" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																									<div class="_3mvi">
																										<div class="_2jkl parallax__container _rp12" id="u_0_7d">
																											<span id="u_0_7e">
																												<img alt="" class="_1fm8 controller-image__left img" src={`117380161_329086004899216_237342119796648931_n.png`}/>
																											</span>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</span>
																				</div>
																				<div class="_66gd _rp13">
																				</div>
																				<div class="_66ge _rp10" filterkeys="" cols="6" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																					<span>
																						<div data-filter-keys="[]" data-cols="6" data-prefix="0" data-pull="0" data-push="0" data-suffix="0">
																							<div>
																								<div class="_45hp _rp11" transitiondelay="0" transitiontime="1000" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="1" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																									<div class="_3mvi">
																										<div class="_2jkl parallax__container" id="u_0_7f">
																											<span id="u_0_7g">
																												<img alt="" class="_1fm8 controller-image__right img" src={`117404602_312818910160269_7623234641776214205_n.png`}/>
																											</span>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<span id="u_0_8">
																	<a href="/support/">
																		<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh h21vdxyh og1bctnk crbex1nj plgazb4k tr6l6ww2 e7vbqoz9 r49rl50p q8abdh1i tcghoajs cp4ljrx1 a0avu08k _rp22">
																			<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																				<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																					<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																						<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																							<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																								<p class="_9d3p _9d3r _9d3u _9d5x home-hero__learn-more"> MÁS INFORMACIÓN </p>
																							</div>
																						</span>
																					</div>
																					<span class="mjgkgano h7ekzin3">
																						<svg class="gjnz7gqr gpm0mln1" height="24" role="img" viewBox="0 0 192 192" width="24">
																							<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																							</path>
																							<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																							</path>
																						</svg>
																					</span>
																				</div>
																			</div>
																		</button>
																	</a>
																</span>
															</div>
														</div>
													</div>
												</div>	
											</section>
										</div>
										<div>
											<section class="_4gsw _7og campaign__section">
												<div class="_7p2">
													<div class="campaign__content-wrapper" id="u_0_a">
														<div>
															<div class="_4r36">
																<div class="_66ge _rp26" filterkeys="" cols="7" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																	<span>
																		<div data-filter-keys="[]" data-cols="7" data-prefix="0" data-pull="0" data-push="0" data-suffix="0">
																			<div class="_2h23 _1020 _2h25">
																				<div>
																					<div class="_45hp " transitiondelay="0" transitiontime="1000" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="1" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																						<div class="_3mvi">
																							<span id="u_0_7p">
																								<img alt="" class="_1fm8 img" src={`121136535_1000558577130332_2563298872536595939_n.jpg`}/>
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</span>
																</div>
																<div class="_66gd _rp13">
																</div>
																<div class="_66ge _rp28" filterkeys="" cols="5" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																	<span>
																		<div data-filter-keys="[]" data-cols="5" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" class="campaign__content-container">
																			<div class="_1g5f _1g5j _1g5g">
																				<div class="_2h23 _2h25 campaign__content _rp17">
																					<div>
																						<div class="_45hp" transitiondelay="0" transitiontime="1000" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="1" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																							<div class="_3mvi">
																								<h3 class="_9d4r _9d4v _9d4y campaign_heading _rp3">Libérate y juega en serio
																								</h3>
																								<p class="_9d3p _9d3t _9d3u _9d3y _9d4f campaign_subheading">No te limites a presionar botones. Hazlo tú mismo. Con el sistema de VR Oculus todo en uno más envolvente hasta la fecha.
																								</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div class="_1g5f _1g5l _1g5g campaign__button-container">
																				<div class="_2h23 _1020 _2h25">
																					<div>
																						<div class="_45hp" transitiondelay="0" transitiontime="1000" direction="up" displacement="80" opacitystart="0" opacityend="1" scalestart="1" scaleend="1" blurstart="0" blurend="0" transformorigin="50% 50%" triggeroffsetpositionfactor="0" transitiontimingfunction="ease" customtransform="">
																							<div class="_3mvi">
																								<a href="https://youtu.be/l2b02sUKf0I">
																									<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh h21vdxyh og1bctnk crbex1nj plgazb4k tr6l6ww2 e7vbqoz9 hcv4kt9o btw4gsph i1qwnor9 lyjjjs6q b3sj53a7 _rp31">
																										<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																											<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																												<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																													<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																														<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																															<p class="_9d3p _9d3r _9d3u _9d3x _9d5x campaign__button-copy">MIRAR VIDEO
																															</p>
																														</div>
																													</span>
																												</div>
																												<span class="mjgkgano h7ekzin3">
																													<svg class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																														<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																														</path>
																														<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																														</path>
																													</svg>
																												</span>
																											</div>
																										</div>
																									</button>
																								</a>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<div class="_rp1">
											</div>
										</div>
										<div>
											<div class="_2h23 _1020 _2h24 choose-your-setup__outer-container _rp17">
												<h2 class="_9d4r _9d4t _9d4x choose-your-setup__heading _rp3">
												ELIGE TU INSTALACIÓN
												</h2>
												<div class="_48ag _48aj _48ak">
													<span id="u_0_1m">
														<a href="/support/">
															<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh h21vdxyh og1bctnk crbex1nj plgazb4k tr6l6ww2 e7vbqoz9 r49rl50p q8abdh1i tcghoajs cp4ljrx1 a0avu08k _rp22">
																<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																	<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																		<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																			<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																				<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																					<p class="_9d3p _9d3r _9d3u _9d5x choose-your-setup__compare-cta"> CONTÁCTANOS 
																					</p>
																				</div>
																			</span>
																		</div>
																		<span class="mjgkgano h7ekzin3">
																			<svg class="gjnz7gqr gpm0mln1" height="24" role="img" viewBox="0 0 192 192" width="24">
																				<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																				</path>
																				<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																				</path>
																			</svg>
																		</span>
																	</div>
																</div>
															</button>
														</a>
													</span>
												</div>
												<div class="" id="u_0_1o">
													<div>
														<div class="_4r36 _rp23">
															<div class="_66ge _rp33" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																<span>
																	<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;tablet&quot;:6,&quot;desktop&quot;:6}">
																		<div class="choose-your-setup__quest-2">
																			<div>
																				<div class="choose-your-setup-card__container">
																					{data.allContentfulProducts.edges.map((edge) => {
																						return (																								
																								<div id="u_0_1p" class="_rp41">
																									<span id="u_0_1q">
																										<a href="/support/">
																											<div id="u_0_1r" class="_rp40">
																												<span id="u_0_1s">
																													<img alt="" class="_1fm8 choose-your-setup-card__image img" src={edge.node.image.file.url}/>
																												</span>
																											</div>
																										</a>
																									</span>
																									<div class="choose-your-setup-card__top-bar">
																										<div class="choose-your-setup-card__info-container">
																											<h4 class="_9d4r _9d4t _9d4z choose-your-setup-card__title _rp3">
																												{edge.node.title}
																											</h4>
																											<p class="_9d3p _9d3r _9d3u _9d3y _9d4f choose-your-setup-card__description">
																												{edge.node.description}
																											</p>
																										</div>
																									</div>
																									<div id="u_0_1t" class="_rp42">
																										<div class="choose-your-setup-card__price-bar">
																											<p class="_9d3p _9d3r _9d3u _9d4e _9d4f choose-your-setup-card__price">
																												<span>{edge.node.price}
																												</span>
																											</p>
																											<span class="_924-">
																												<i class="img sp_wUQJPOLecXu sx_836920">
																												</i>
																											</span>
																										</div>
																										<div>
																											<div>
																												<div>
																													<span id="u_0_6v">
																														<div id="u_0_6w" class="_rp43">
																															<button aria-label="Open Modal" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 llxe0dxk nmxi6925 ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp20">
																																<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																																	<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																		<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																			<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																				<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																					<div class="choose-your-setup-card__price-bar-hover">
																																						<p class="_9d3p _9d3r _9d3u _9d3x _9d5x choose-your-setup-card__price">
																																							<span class="choose-your-setup-card__starting-at">Desde
																																							</span>
																																							<span>{edge.node.price}
																																							</span>
																																						</p>
																																						<p class="_9d3p _9d3r _9d3u _9d3x _9d5x choose-your-setup-card__cta choose-your-setup-card__add-to-cart">COMPRAR
																																						</p>
																																					</div>
																																				</div>
																																			</span>
																																		</div>
																																		<span class="mjgkgano h7ekzin3">
																																			<svg aria-label="Open Modal" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																				<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																				</path>
																																			</svg>
																																		</span>
																																	</div>
																																</div>
																															</button>
																														</div>
																													</span>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																								)
																					})}
																				</div>
																			</div>
																		</div>	
																	</div>
																</span>
															</div>
															<div class="_66gd _rp32">
															</div>
															<div class="_66ge _rp33" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																<span>
																	<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;tablet&quot;:6,&quot;desktop&quot;:6}">
																		<div class="choose-your-setup__rift-s">
																			<div>
																				<div class="choose-your-setup-card__container">
																					<div id="u_0_1v" class="_rp34">
																						<span id="u_0_1w">
																							<a href="/support/">
																								<div id="u_0_1x" class="_rp35">
																									<span id="u_0_1y">
																										<img alt="" class="_1fm8 choose-your-setup-card__image img" src={`117965985_734800247089153_4706568241284593795_n.jpg`}/>
																									</span>
																								</div>
																							</a>
																						</span>
																						<div class="choose-your-setup-card__top-bar">
																							<div class="choose-your-setup-card__info-container">
																								<h4 class="_9d4r _9d4t _9d4z choose-your-setup-card__title _rp3">OCULUS&nbsp;RIFT&nbsp;S
																								</h4>
																								<p class="_9d3p _9d3r _9d3u _9d3y _9d4f choose-your-setup-card__description">Juegos de VR para PC
																								</p>
																							</div>
																						</div>
																						<div id="u_0_1z" class="_rp36">
																							<div class="choose-your-setup-card__price-bar">
																								<p class="_9d3p _9d3r _9d3u _9d4e _9d4f choose-your-setup-card__price">
																									<span class="rift-s__promo-price-strike-through">
																										<s> S/ 2999 
																										</s>
																									</span>
																									<span>S/ 2499
																									</span>
																								</p>
																								<span class="_924-">
																									<i class="img sp_wUQJPOLecXu sx_836920">
																									</i>
																								</span>
																							</div>
																							<div>
																								<div>
																									<div>
																										<span id="u_0_6y">
																											<div id="u_0_6z" class="_rp37">
																												<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																													<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																														<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																															<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																	<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																		<div class="choose-your-setup-card__price-bar-hover">
																																			<p class="_9d3p _9d3r _9d3u _9d3x _9d5x choose-your-setup-card__price">
																																				<span>S/ 2499
																																				</span>
																																			</p>
																																			<p class="_9d3p _9d3r _9d3u _9d3x _9d5x choose-your-setup-card__cta choose-your-setup-card__add-to-cart">COMPRAR
																																			</p>
																																		</div>
																																	</div>
																																</span>
																															</div>
																															<span class="mjgkgano h7ekzin3">
																																<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																	<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																	</path>
																																</svg>
																															</span>
																														</div>
																													</div>
																												</button>
																											</div>
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</span>
															</div>
														</div>
														<div class="_4r36">
															<div class="_66ge _rp39" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																<span>
																	<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0">
																		<div class="_48ag _48ai">
																			<span id="u_0_21">
																				<a class="_9d5l _9d5n _9d5- _9d5z choose-your-setup__button" href="/support/">CONTÁCTANOS
																					<span class="_9d5y">
																						<span class="_924-">
																							<i class="img sp_wUQJPOLecXu sx_db63aa">
																							</i>
																						</span>
																					</span>
																				</a>
																			</span>
																		</div>
																	</div>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<section class="_4gsw _7of accessories-module__section">
												<div class="_7p2">
													<div class="_2h23 _1020 _2h24 _1if- accessories-module__mobile-only _rp17">
														<h3 class="_9d4r _9d4t _9d4y accessories-module__title _rp3">JUEGA A TU MANERA
														</h3>
													</div>
													<div class="_1g5f _1g5k _1g5h accessories-module__mobile-only">
														<div class="_2h23 _1020 _2h24 _1if-">
															<div class="accessories-module__mobile-card-container">
																<div class="accessories-module__mobile-card accessories-module__right-margin">
																	<div>
																		<span id="u_0_22">
																			<div id="u_0_23" class="_rpac1">
																				<div class="accessories-module__card-container">
																					<a href="/">
																						<div id="u_0_24" class="_rpac2">
																							<div id="u_0_25" class="_rpac3">
																								<div id="u_0_26" class="_rpac4">
																									<div id="u_0_27" class="rpac5">
																										<div class="_1g5f _1g5k _1g5h">
																											<div class="_2h23 _1020 _2h24 _1if-">
																												<img class="accessories-module__card-image img" src={`119348522_600076493996658_3107247883672075326_n.jpg`} width="100%" alt=""/>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																							<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																								<div class="accessories-module__card-top-bar">
																									<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Correa Elite con batería y estuche para Quest&nbsp;2
																									</h6>
																								</div>
																							</div>
																							<div id="u_0_28" class="_rpac6">
																								<div class="_1g5f _1g5k _1g5g">
																									<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																										<div id="u_0_29" class="_rpac7">
																											<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Protege tu sistema cuando estés en tu casa o de viaje. En el estuche, puedes llevar el visor, cualquiera de las correas Elite, los controladores, los cables y el adaptador de corriente.
																											</p>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</a>
																					<div id="u_0_2a" class="_rpac8">
																						<div class="accessories-module__card-bottom-bar">
																							<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																								<span>S/ 999
																								</span>
																							</p>
																							<span class="_924-">
																								<i class="img sp_wUQJPOLecXu sx_836920">
																								</i>
																							</span>
																						</div>
																						<div id="u_0_2b" class="_rpac9">
																							<div>
																								<div>
																									<div>
																										<div>
																											<span id="u_0_71">
																												<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																													<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																														<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																															<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																	<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																		<div class="accessories-module__add-to-cart-wrapper">
																																			<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																				<span>S/ 999
																																				</span>
																																			</p>
																																			<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																			</p>
																																		</div>
																																	</div>
																																</span>
																															</div>
																															<span class="mjgkgano h7ekzin3">
																																<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																	<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																	</path>
																																</svg>
																															</span>
																														</div>
																													</div>
																												</button>
																											</span>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</span>
																	</div>
																</div>
																<div class="accessories-module__mobile-card">
																	<div>
																		<span id="u_0_2d">
																			<div id="u_0_2e" class="_rpac1">
																				<div class="accessories-module__card-container">
																					<a href="/">
																						<div id="u_0_2f" class="_rpac10">
																							<div id="u_0_2g" class="_rpac11">
																								<div id="u_0_2h" class="_rpac12">
																									<div id="u_0_2i" class="_rpac13">
																										<div class="_1g5f _1g5k _1g5h">
																											<div class="_2h23 _1020 _2h24 _1if-">
																												<img class="accessories-module__card-image img" src={`118794732_645989712715250_273219447587057415_n.jpg`} width="100%" alt=""/>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																							<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																								<div class="accessories-module__card-top-bar">
																									<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Kit de accesorios para Quest&nbsp;2
																									</h6>
																								</div>
																							</div>
																							<div id="u_0_2j" class="_rpac14">
																								<div class="_1g5f _1g5k _1g5g">
																									<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																										<div id="u_0_2k" class="_rpac15">
																											<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Este kit incluye un par de bloqueadores de luz y dos interfaces faciales intercambiables que se adaptan a rostros anchos o angostos.
																											</p>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</a>
																					<div id="u_0_2l" class="_rpac16">
																						<div class="accessories-module__card-bottom-bar">
																							<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																								<span>S/ 299
																								</span>
																							</p>
																							<span class="_924-">
																								<i class="img sp_wUQJPOLecXu sx_836920">
																								</i>
																							</span>
																						</div>
																						<div id="u_0_2m" class="_rpac17">
																							<div>
																								<div>
																									<div>
																										<span id="u_0_73">
																											<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																												<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																													<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																														<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																															<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																	<div class="accessories-module__add-to-cart-wrapper">
																																		<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																			<span>S/ 299
																																			</span>
																																		</p>
																																		<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																		</p>
																																	</div>
																																</div>
																															</span>
																														</div>
																														<span class="mjgkgano h7ekzin3">
																															<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																</path>
																															</svg>
																														</span>
																													</div>
																												</div>
																											</button>
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</span>
																	</div>
																</div>
															</div>
															<div class="accessories-module__mobile-card-shop-all">
																<div>
																	<span id="u_0_2o">
																		<a href="/support/">
																			<div id="u_0_2p" class="_rpac18">
																				<div class="_2h23 _1020 _2h24 accessories-module__card-container-black">
																					<div class="_2h23 _1020 _2h25 accessories-module__shop-all-card _rp17">
																						<h4 class="_9d4r _9d4v _9d4z accessories-module__shop-all-title _rp3">VER TODOS LOS ACCESORIOS
																						</h4>
																						<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh mmfkxmmj klrg3ln2 d1l52324 q4mlfhxt tr6l6ww2 e7vbqoz9 hcv4kt9o btw4gsph i1qwnor9 lyjjjs6q b3sj53a7">
																							<div class="q8b6gf7a finb4tb1 o16mf53o">
																								<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																									<span class="mjgkgano">
																										<svg class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																											<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																											</path>
																											<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																											</path>
																										</svg>
																									</span>
																								</div>
																							</div>
																						</button>
																					</div>
																				</div>
																			</div>
																		</a>
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div class="_48ag _48aj">
														<div class="_1g5f _1g5k _1g5h">
															<div class="_2h23 _1020 _2h24 _1if- _rp17">
																<div class="" id="u_0_2r">
																	<div>
																		<div class="_4r36 _rpac19">
																			<div class="_66ge _rpac20" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																				<span>
																					<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;tablet&quot;:6}" class="accessories-module__tablet-left-cell">
																						<h3 class="_9d4r _9d4t _9d4y accessories-module__title _rp3">JUEGA A TU MANERA
																						</h3>
																							<div>
																								<span id="u_0_2s">
																									<div id="u_0_2t" class="_rpac1">
																										<div class="accessories-module__card-container">
																											<a href="/">
																												<div id="u_0_2u" class="_rpac21">
																													<div id="u_0_2v" class="_rpac22">
																														<div id="u_0_2w" class="_rpac23">
																															<div id="u_0_2x" class="_rpac24">
																																<div class="_1g5f _1g5k _1g5h">
																																	<div class="_2h23 _1020 _2h24 _1if-">
																																		<img class="accessories-module__card-image img" src={`119348522_600076493996658_3107247883672075326_n.jpg`} width="100%" alt=""/>
																																	</div>
																																</div>
																															</div>
																														</div>
																													</div>
																													<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																														<div class="accessories-module__card-top-bar">
																															<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Correa Elite con batería y estuche para Quest&nbsp;2
																															</h6>
																														</div>
																													</div>
																													<div id="u_0_2y" class="_rpac25">
																														<div class="_1g5f _1g5k _1g5g">
																															<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																																<div id="u_0_2z" class="_rpac26">
																																	<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Protege tu sistema cuando estés en tu casa o de viaje. En el estuche, puedes llevar el visor, cualquiera de las correas Elite, los controladores, los cables y el adaptador de corriente.
																																	</p>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																											</a>
																											<div id="u_0_30" class="_rpac27">
																												<div class="accessories-module__card-bottom-bar">
																													<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																														<span>S/ 999
																														</span>
																													</p>
																													<span class="_924-">
																														<i class="img sp_wUQJPOLecXu sx_836920">
																														</i>
																													</span>
																												</div>
																												<div id="u_0_31" class="_rpac28">
																													<div>
																														<div>
																															<div>
																																<div>
																																	<span id="u_0_75">
																																		<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																																			<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																																				<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																					<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																						<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																							<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																								<div class="accessories-module__add-to-cart-wrapper">
																																									<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																										<span>S/ 999
																																										</span>
																																									</p>
																																									<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																									</p>
																																								</div>
																																							</div>
																																						</span>
																																					</div>
																																					<span class="mjgkgano h7ekzin3">
																																						<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																							<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																							</path>
																																						</svg>
																																					</span>
																																				</div>
																																			</div>
																																		</button>
																																	</span>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</span>
																							</div>
																						</div>
																					</span>
																				</div>
																			</div>
																			<div class="_4r36">
																				<div class="_66ge _rpac29" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																					<span>
																						<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;tablet&quot;:6}" class="accessories-module__tablet-right-cell">
																							<div>
																								<span id="u_0_33">
																									<div id="u_0_34" class="_rpac1">
																										<div class="accessories-module__card-container">
																											<a href="/support/">
																												<div id="u_0_35" class="_rpac30">
																													<div id="u_0_36" class="_rpac31">
																														<div id="u_0_37" class="_rpac32">
																															<div id="u_0_38" class="_rpac33">
																																<div class="_1g5f _1g5k _1g5h">
																																	<div class="_2h23 _1020 _2h24 _1if-">
																																		<img class="accessories-module__card-image img" src={`118794732_645989712715250_273219447587057415_n.jpg`} width="100%" alt=""/>
																																	</div>
																																</div>
																															</div>
																														</div>
																													</div>
																													<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																														<div class="accessories-module__card-top-bar">
																															<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Kit de accesorios para Quest&nbsp;2
																															</h6>
																														</div>
																													</div>
																													<div id="u_0_39" class="_rpac34">
																														<div class="_1g5f _1g5k _1g5g">
																															<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																																<div id="u_0_3a" class="_rpac35">
																																	<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Este kit incluye un par de bloqueadores de luz y dos interfaces faciales intercambiables que se adaptan a rostros anchos o angostos.
																																	</p>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																											</a>
																										<div id="u_0_3b" class="_rpac36">
																											<div class="accessories-module__card-bottom-bar">
																												<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																													<span>S/ 299
																													</span>
																												</p>
																												<span class="_924-">
																													<i class="img sp_wUQJPOLecXu sx_836920">
																													</i>
																												</span>
																											</div>
																											<div id="u_0_3c" class="_rpac37">
																												<div>
																													<div>
																														<div>
																															<span id="u_0_77">
																																<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																																	<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																																		<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																			<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																				<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																					<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																						<div class="accessories-module__add-to-cart-wrapper">
																																							<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																								<span>S/ 299
																																								</span>
																																							</p>
																																							<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																							</p>
																																						</div>
																																					</div>
																																				</span>
																																			</div>
																																			<span class="mjgkgano h7ekzin3">
																																				<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																				<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																				</path>
																																				</svg>
																																			</span>
																																		</div>
																																	</div>
																																</button>
																															</span>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</span>
																						</div>
																						<div>
																							<span id="u_0_3e">
																								<a href="/support/">
																									<div id="u_0_3f" class="_rpac38">
																										<div class="_2h23 _1020 _2h24 accessories-module__card-container-black">
																											<div class="_2h23 _1020 _2h25 accessories-module__shop-all-card _rp17">
																												<h4 class="_9d4r _9d4v _9d4z accessories-module__shop-all-title _rp3">VER TODOS LOS ACCESORIOS
																												</h4>
																												<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh mmfkxmmj klrg3ln2 d1l52324 q4mlfhxt tr6l6ww2 e7vbqoz9 hcv4kt9o btw4gsph i1qwnor9 lyjjjs6q b3sj53a7">
																													<div class="q8b6gf7a finb4tb1 o16mf53o">
																														<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																															<span class="mjgkgano">
																																<svg class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																	<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																																	</path>
																																	<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																																	</path>
																																</svg>
																															</span>
																														</div>
																													</div>
																												</button>
																											</div>
																										</div>
																									</div>
																								</a>
																							</span>
																						</div>
																					</div>
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_48ag _48ak accessories-module__desktop-container">
														<div class="_1g5f _1g5k _1g5g">
															<div class="_2h23 _1020 _2h24 _1if- _rp17">
																<h3 class="_9d4r _9d4t _9d4y accessories-module__title _rp3">JUEGA A TU MANERA
																</h3>
															</div>
														</div>
														<div class="_1g5f _1g5k _1g5h">
															<div class="_2h23 _1020 _2h24 _1if- accessories-module__desktop-content">
																<div class="" id="u_0_3h">
																	<div>
																		<div class="_4r36">
																			<div class="_66ge _rpac39" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																				<span>
																					<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;desktop&quot;:4}">
																						<div>
																							<span id="u_0_3i">
																								<div id="u_0_3j" class="rpac1">
																									<div class="accessories-module__card-container">
																										<a href="/support/">
																											<div id="u_0_3k" class="_rpac40">
																												<div id="u_0_3l" class="_rpac41">
																													<div id="u_0_3m" class="_rpac42">
																														<div id="u_0_3n" class="_rpac43">
																															<div class="_1g5f _1g5k _1g5h">
																																<div class="_2h23 _1020 _2h24 _1if-">
																																	<img class="accessories-module__card-image img" src={`119348522_600076493996658_3107247883672075326_n.jpg`} width="100%" alt=""/>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																												<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																													<div class="accessories-module__card-top-bar">
																														<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Correa Elite con batería y estuche para Quest&nbsp;2
																														</h6>
																													</div>
																												</div>
																												<div id="u_0_3o" class="_rpac44">
																													<div class="_1g5f _1g5k _1g5g">
																														<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																															<div id="u_0_3p" class="_rpac45">
																																<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Protege tu sistema cuando estés en tu casa o de viaje. En el estuche, puedes llevar el visor, cualquiera de las correas Elite, los controladores, los cables y el adaptador de corriente.
																																</p>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</a>
																										<div id="u_0_3q" class="_rpac46">
																											<div class="accessories-module__card-bottom-bar">
																												<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																													<span>S/ 999
																													</span>
																												</p>
																												<span class="_924-">
																													<i class="img sp_wUQJPOLecXu sx_836920">
																													</i>
																												</span>
																											</div>
																											<div id="u_0_3r" class="_rpac47">
																												<div>
																													<div>
																														<div>
																															<div>
																																<span id="u_0_79">
																																	<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																																		<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																																			<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																				<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																					<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																						<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																							<div class="accessories-module__add-to-cart-wrapper">
																																								<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																									<span>S/ 999
																																									</span>
																																								</p>
																																								<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																								</p>
																																							</div>
																																						</div>
																																					</span>
																																				</div>
																																				<span class="mjgkgano h7ekzin3">
																																					<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																						<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																						</path>
																																					</svg>
																																				</span>
																																			</div>
																																		</div>
																																	</button>
																																</span>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</span>
																						</div>
																					</div>
																				</span>
																			</div>
																			<div class="_66gd _rpac48">
																			</div>
																			<div class="_66ge _rpac49" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																				<span>
																					<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;desktop&quot;:4}">
																						<div>
																							<span id="u_0_3t">
																								<div id="u_0_3u" class="_rpac1">
																									<div class="accessories-module__card-container">
																										<a href="/support/">
																											<div id="u_0_3v" class="_rpac50">
																												<div id="u_0_3w" class="_rpac51">
																													<div id="u_0_3x" class="_rpac52">
																														<div id="u_0_3y" class="_rpac53">
																															<div class="_1g5f _1g5k _1g5h">
																																<div class="_2h23 _1020 _2h24 _1if-">
																																	<img class="accessories-module__card-image img" src={`118794732_645989712715250_273219447587057415_n.jpg`} width="100%" alt=""/>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																												<div class="_2h23 _2h24 accessories-module__card-title-container _rp17">
																													<div class="accessories-module__card-top-bar">
																														<h6 class="_9d4r _9d4t _9d4_ accessories-module__card-title _rp3">Kit de accesorios para Quest&nbsp;2
																														</h6>
																													</div>
																												</div>
																												<div id="u_0_3z" class="_rpac54">
																													<div class="_1g5f _1g5k _1g5g">
																														<div class="_2h23 _1020 _2h24 accessories-module__card-description-outer-container _rp17">
																															<div id="u_0_40" class="_rpac55">
																																<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-description">Este kit incluye un par de bloqueadores de luz y dos interfaces faciales intercambiables que se adaptan a rostros anchos o angostos.
																																</p>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</a>
																										<div id="u_0_41" class="_rpac56">
																											<div class="accessories-module__card-bottom-bar">
																												<p class="_9d3p _9d3r _9d3u _9d3y _9d4f accessories-module__card-price">
																													<span>S/ 299
																													</span>
																												</p>
																												<span class="_924-">
																													<i class="img sp_wUQJPOLecXu sx_836920">
																													</i>
																												</span>
																											</div>
																											<div id="u_0_42" class="_rpac57">
																												<div>
																													<div>
																														<div>
																															<span id="u_0_7b">
																																<button aria-label="addToCart" class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa ay9c3q3o ahevtls2 is92cqwc pgzuijzi h21vdxyh og1bctnk crbex1nj plgazb4k c45wfpm5 tnboieqe madsewh0 ls1crbr3 anieyyru b3sj53a7 _rp38">
																																	<div class="q8b6gf7a finb4tb1 o16mf53o ftyx7bxa">
																																		<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																			<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																																				<span class="h7ln79zn qz4j89zn h6zx27dw ovxdpc04 ruat3efa dfqm9sp0 g1ys7phv ibk08iwq te3xmyo8 dbo6tyrk ov8xg9lf a4jvux7b">
																																					<div class="ov8xg9lf dbo6tyrk te3xmyo8">
																																						<div class="accessories-module__add-to-cart-wrapper">
																																							<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta">
																																								<span>S/299
																																								</span>
																																							</p>
																																							<p class="_9d3p _9d3r _9d3u _9d3x _9d5x accessories-module__card-cta accessories-module__card-add-to-cart">COMPRAR
																																							</p>
																																						</div>
																																					</div>
																																				</span>
																																			</div>
																																			<span class="mjgkgano h7ekzin3">
																																				<svg aria-label="addToCart" class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																					<path d="M104 120.017H88V79.9996H48V63.9996H88V24.0166H104V63.9996H144V79.9996H104V120.017ZM144 152V128H160V160C160 164.418 156.418 168 152 168H40C35.582 168 32 164.418 32 160V128H48V152H144Z">
																																					</path>
																																				</svg>
																																			</span>
																																		</div>
																																	</div>
																																</button>
																															</span>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</span>
																						</div>
																					</div>
																				</span>
																			</div>
																			<div class="_66gd _rpac48">
																			</div>
																			<div class="_66ge _rpac58" filterkeys="" cols="12" prefix="0" pull="0" push="0" suffix="0" responsivecols="[object Object]" responsiveprefix="[object Object]" responsivepull="[object Object]" responsivepush="[object Object]" responsivesuffix="[object Object]">
																				<span>
																					<div data-filter-keys="[]" data-cols="12" data-prefix="0" data-pull="0" data-push="0" data-suffix="0" data-responsive-cols="{&quot;desktop&quot;:4}">
																						<div>
																							<span id="u_0_44">
																								<a href="/support/">
																									<div id="u_0_45" class="rpac59">
																										<div class="_2h23 _1020 _2h24 accessories-module__card-container-black">
																											<div class="_2h23 _1020 _2h25 accessories-module__shop-all-card _rp17">
																												<h4 class="_9d4r _9d4v _9d4z accessories-module__shop-all-title _rp3">VER TODOS LOS ACCESORIOS
																												</h4>
																												<button class="b52kj89e sq39p0kj rbj7b54s pjumf6uq a0585srg i92ihv9n baw4mjhw ptamchqq j0w2kb1n i2wm47ke qol2cro8 okr54ooa klsajntx rd0pab4s oq0i6scd ayos5gsh mmfkxmmj klrg3ln2 d1l52324 q4mlfhxt tr6l6ww2 e7vbqoz9 hcv4kt9o btw4gsph i1qwnor9 lyjjjs6q b3sj53a7">
																													<div class="q8b6gf7a finb4tb1 o16mf53o">
																														<div class="q8b6gf7a finb4tb1 kzuqcr2d tq4ouhqd">
																															<span class="mjgkgano">
																																<svg class="gjnz7gqr j659ius4" height="24" role="img" viewBox="0 0 192 192" width="24">
																																	<path d="M144 104L32.0005 104L32.0005 88L144 88L144 104Z">
																																	</path>
																																	<path d="M98.319 152L154 96L98.319 40L87 51.383L131.363 96L87 140.617L98.319 152Z">
																																	</path>
																																</svg>
																															</span>
																														</div>
																													</div>
																												</button>
																											</div>
																										</div>
																									</div>
																								</a>
																							</span>
																						</div>
																					</div>
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
										</div>
									</div>
								</div>
							</div>
						</div>		
					</div>
				</div>
			</body>
		</html>
    </main>
  )
}

export default IndexPage
